#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.top-nav{
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    background: var(--adm-color-background);
    border-bottom: 1px solid #ccc;
}

.top-nav > div {
    transition: all 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.bottom-nav{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    background: var(--adm-color-background);
    border-top: 1px solid #ccc;
}

.invoiceItem:before{
    content: '';
    width: 3px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-image: var(--left-border-image);
    border-left: var(--left-border);
}

.invoiceItem:after{
    content: '';
    width: 3px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-image: var(--right-border-image);
    border-right: var(--right-border);
}

.wfstep{
    padding: 0.2rem 0.5rem;
    
    border-radius: 50%;
    background-color: #f5f5f5;
    font-size: 0.8em;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 0 0 1px #e0e0e0;
    
}

.HomeBody .adm-card {
    margin: 0.5rem;
    border-radius: 0.3rem
}

.adm-card-header-title h4, .adm-collapse-title h4, .adm-list-header h4{
    margin: 0;
    display: inline-block;
    color: var(--color-text-secondary);
    font-weight: 700;
    font-size: var(--adm-font-size-7)
}

.invoice-detail-card-body{
    color: #666;
}

.adm-form-item-label{
    font-weight: 700;
}

ul.sub-list{
    font-size: 88%;
    margin: 0;
    padding: 0 1rem;
}

ul.sub-list li{
    padding: 0.3rem 0;
}

.invoiceId{
    color:#8b0000;
    font-family:monospace;
}