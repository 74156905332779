body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-muted{
  font-size: 80%;
  color: var(--adm-color-weak);
}

.text-right{
  text-align: right;
}

/* Needed to display the vendor badges returned from the server */
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: var(--adm-color-white);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: var(--adm-color-text-secondary);
  border-radius: 10px;
}

:root:root{
  --adm-color-primary: #042DBB; /*#0C31ED;*/
  --adm-color-secondary: #CCAC0A;

  --color-text-primary:#042DBB;
  --color-text-dark-primary: #B9B9FF;
  --color-text-secondary: #A37F03;
  --color-text-dark-secondary: #E9D740;

  --color-background-primary: #7E85FD;
  --color-background-secondary: #F9F27C;

  --color-background-dark-primary: #001E58;
  --color-background-dark-secondary: #795701;
}